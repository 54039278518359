import { Outlet } from "react-router-dom";
import Card from "../components/Card";
import ExternalLink from "../components/ExternalLink";

const Layout = () => {
  return (
    <div className="Wrap">
      <Card title="Warpfile\" col="1">
        Warpfile is a service to send and receive files from pc to pc.
        {process.env.NODE_ENV === "development" ? (
          <p> {"It is in development mode!"} </p>
        ) : (
          ""
        )}
      </Card>
      <Outlet />
      <Card col="1">
        &copy; 2022 Yanick Stephan |{" "}
        <ExternalLink href="https://yanick.gay">About</ExternalLink> |{" "}
        <ExternalLink href="mailto:support@yanick.gay">Contact</ExternalLink> |{" "}
        <ExternalLink href="https://imprint.yanick.gay/">Imprint</ExternalLink>
      </Card>
    </div>
  );
};

export default Layout;
