import React from "react";
import "./ErrorCard.css";

function ErrorCard(props) {
  const cardRef = React.useRef();

  const close = () => {
    cardRef.current.remove();
  };

  return (
    <div className="ErrorCard-parent">
      <div ref={cardRef} className="ErrorCard-element">
        <h1>Error!</h1>
        <button onClick={close}>X</button>
        <br />
        <div className="ErrorCard-content">{props.errorMessage}</div>
      </div>
    </div>
  );
}
export default ErrorCard;
