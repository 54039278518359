import "./ExternalLink.css";

function ExternalLink(props) {
  return (
    <a className="ExternalLink" {...props}>
      {props.children}
    </a>
  );
}
export default ExternalLink;
