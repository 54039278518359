const When = (props) => {
  const shouldRender =
    typeof props.condition === "function"
      ? props.condition()
      : !!props.condition;

  if (!shouldRender) return null;

  return props.children;
};
export default When;