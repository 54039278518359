import "./TransferStatus.css";
import Row from "../Row";

function TransferStatus(props) {
  const TransferStatusProgress = {
    width: `${props.progress}%`,
  };
  return (
    <Row>
      <div className="TransferStatus-parent">
        <span className="TransferStatus-text">
          {`${Math.round(parseFloat(props.progress) * 100) / 100}%`}
        </span>
        <span className="TransferStatus-text">
          {`${props.speed} ${props.speedUnit}/s`}
        </span>
        <div className="TransferStatus-progress">
          <div
            style={TransferStatusProgress}
            className="TransferStatus-progressBar"
          ></div>
        </div>
      </div>
    </Row>
  );
}
export default TransferStatus;
