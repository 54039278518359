import React from "react";
import "./PinInput.css";

function PinInput(props) {
  const input = [
    React.useRef(),
    React.useRef(),
    React.useRef(),
    React.useRef(),
    React.useRef(),
    React.useRef(),
    React.useRef(),
    React.useRef(),
    React.useRef(),
  ];

  const triggerChangeEvent = () => {
    props.onChange({
      id:
        input[0].current.value +
        input[1].current.value +
        input[2].current.value +
        input[3].current.value +
        input[4].current.value +
        input[5].current.value +
        input[6].current.value +
        input[7].current.value +
        input[8].current.value,
    });
  };

  const handleKeyDown = (e) => {
    e.preventDefault();
    var nextTarget;
    const inputNumber = parseInt(e.key);
    if (isNaN(parseInt(inputNumber, 10))) {
      // TODO: add CTRL + V
      if (e.key === "Backspace") {
        const currentTarget = parseInt(e.target.id, 10);
        if (input[currentTarget].current.value.length === 0) {
          nextTarget = currentTarget - 1;
        } else {
          nextTarget = currentTarget;
        }
        if (nextTarget !== -1) {
          input[nextTarget].current.value = "";
          input[nextTarget].current.focus();
        }
      }
      triggerChangeEvent();
      return;
    }
    e.target.value = inputNumber;
    nextTarget = parseInt(e.target.id, 10) + 1;
    if (nextTarget !== 9) {
      input[nextTarget].current.focus();
    }
    triggerChangeEvent();
  };

  return (
    <div className="PinInput-parent">
      <input
        ref={input[0]}
        onKeyDown={handleKeyDown}
        id="0"
        className="PinInput-element"
        type="number"
        placeholder="0"
        maxLength="1"
        size="1"
      />
      <input
        ref={input[1]}
        onKeyDown={handleKeyDown}
        id="1"
        className="PinInput-element"
        type="number"
        placeholder="0"
        maxLength="1"
        size="1"
      />
      <input
        ref={input[2]}
        onKeyDown={handleKeyDown}
        id="2"
        className="PinInput-element"
        type="number"
        placeholder="0"
        maxLength="1"
        size="1"
      />
      <div className="PinInput-space" />
      <input
        ref={input[3]}
        onKeyDown={handleKeyDown}
        id="3"
        className="PinInput-element"
        type="number"
        placeholder="0"
        maxLength="1"
        size="1"
      />
      <input
        ref={input[4]}
        onKeyDown={handleKeyDown}
        id="4"
        className="PinInput-element"
        type="number"
        placeholder="0"
        maxLength="1"
        size="1"
      />
      <input
        ref={input[5]}
        onKeyDown={handleKeyDown}
        id="5"
        className="PinInput-element"
        type="number"
        placeholder="0"
        maxLength="1"
        size="1"
      />
      <div className="PinInput-space" />
      (
      <input
        ref={input[6]}
        onKeyDown={handleKeyDown}
        id="6"
        className="PinInput-element"
        type="number"
        placeholder="0"
        maxLength="1"
        size="1"
      />
      <input
        ref={input[7]}
        onKeyDown={handleKeyDown}
        id="7"
        className="PinInput-element"
        type="number"
        placeholder="0"
        maxLength="1"
        size="1"
      />
      <input
        ref={input[8]}
        onKeyDown={handleKeyDown}
        id="8"
        className="PinInput-element"
        type="number"
        placeholder="0"
        maxLength="1"
        size="1"
      />
      )
    </div>
  );
}
export default PinInput;
