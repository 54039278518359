import "./Card.css";

function Card(props) {
  return (
    <div className={`Card Card-col-${props.col}`}>
      {props.title ? <><h1>{props.title}</h1><br /></> : ""}
      <div className={`Card-content-${props.title ? 'with-title' : 'without-title'}`}>
        {props.children}
      </div>
    </div>
  );
}
export default Card;
