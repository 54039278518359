import React from "react";
import "./FileInput.css";

const FileInput = React.forwardRef((props, ref) => {
  const fileRef = React.useRef();
  const inputRef = React.useRef();
  const [inputNameState, setInputNameState] = React.useState("Select file...");

  const triggerChangeEvent = () => {
    props.onChange({ file: fileRef.current.files[0] });
  };

  const handleChange = (e) => {
    const input = e.target.files[0];
    if (!input) {
      setInputNameState("Select file...");
      triggerChangeEvent();
      return;
    }
    setInputNameState(input.name);
    triggerChangeEvent();
  };

  React.useImperativeHandle(ref, () => ({
    getFile: () => {
      return fileRef.current.files[0];
    },
  }));

  return (
    <>
      <input
        ref={inputRef}
        className="FileInput-button"
        type="button"
        value={inputNameState}
        onClick={() => fileRef.current.click()}
      />
      <input
        ref={fileRef}
        onChange={handleChange}
        multiple={false}
        type="file"
        hidden
      />
    </>
  );
});

export default FileInput;
