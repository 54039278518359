import "./AbortButton.css";
import Row from "../Row";

function AbortButton(props) {
  return (
    <Row>
      <input
        className="AbortButton"
        type="button"
        value="Abort Transfer"
        {...props}
      />
    </Row>
  );
}
export default AbortButton;
