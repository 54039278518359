var config = {};
config.server = {};

config.server.host = process.env.REACT_APP_API_HOST;
config.server.port = process.env.REACT_APP_API_PORT;
config.server.path = "/peer";
config.server.secure = process.env.REACT_APP_API_SECURE === "true";
config.server.debug = process.env.REACT_APP_DEBUG;
config.server.config = {
  iceServers: [
    {
      urls: "stun:openrelay.metered.ca:80",
    },
    {
      urls: "turn:openrelay.metered.ca:80",
      username: "openrelayproject",
      credential: "openrelayproject",
    },
    {
      urls: "turn:openrelay.metered.ca:443",
      username: "openrelayproject",
      credential: "openrelayproject",
    },
    {
      urls: "turn:openrelay.metered.ca:443?transport=tcp",
      username: "openrelayproject",
      credential: "openrelayproject",
    },
  ],
};
config.maxSizeForUpload = process.env.REACT_APP_MAX_SIZE_FOR_UPLOAD;
config.apiUrl = config.server.secure
  ? "https://" + config.server.host + ":" + config.server.port
  : "http://" + config.server.host + ":" + config.server.port;
config.bytesPerChunk = 1024;

export default config;
