import React from "react";
import "./PinDisplay.css";
import copy from "copy-to-clipboard";
import Row from "../Row";

function PinDisplay(props) {
  function copyButton() {
    copy(props.pin);
  }
  return (
    <Row>
      <div className="PinDisplay-parent">
        <div className="PinDisplay-element">{props.pin.charAt(0)}</div>
        <div className="PinDisplay-element">{props.pin.charAt(1)}</div>
        <div className="PinDisplay-element">{props.pin.charAt(2)}</div>
        <div className="PinDisplay-space"></div>
        <div className="PinDisplay-element">{props.pin.charAt(3)}</div>
        <div className="PinDisplay-element">{props.pin.charAt(4)}</div>
        <div className="PinDisplay-element">{props.pin.charAt(5)}</div>
        <input
          className="PinDisplay-button"
          type="button"
          value="Copy"
          onClick={copyButton}
        />
      </div>
    </Row>
  );
}
export default PinDisplay;
