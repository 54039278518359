import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Axios from "axios";
import Peer from "peerjs";
import config from "../config";
import When from "../components/When";
import Row from "../components/Row";
import Card from "../components/Card";
import AbortButton from "../components/AbortButton";
import Button from "../components/Button";
import PinDisplay from "../components/PinDisplay";
import TransferStatus from "../components/TransferStatus";

const Upload = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialConnection = React.useRef(false);
  const [uploadPin, setUploadPin] = React.useState("");
  const [progressState, setProgressState] = React.useState("0");
  const peer = React.useRef(null);
  const connection = React.useRef(null);

  const currentChunk = React.useRef(0);
  const totalChunks = React.useRef(0);
  const finishedUpload = React.useRef(false);
  var fileReader = new FileReader();

  const lastTimestamp = React.useRef(0);
  const lastUploadedBytes = React.useRef(0);
  const networkSpeed = React.useRef(0.0);

  function dummy() {
    navigate(-1);
  }

  function abort() {
    finishedUpload.current = true;
    peer.current.destroy();
    navigate("/");
  }

  function sizeStringToBytes(str) {
    var num = parseInt(str.slice(0, -1));
    switch (str.slice(-1).toLowerCase()) {
      case "b":
        return num;
      case "k":
        return num * 1024;
      case "m":
        return num * 1024 * 1024;
      case "g":
        return num * 1024 * 1024 * 1024;
      default:
        return num;
    }
  }

  function getConnectionStats() {
    const currentTime = new Date().getTime();
    const timeDuration = (currentTime - lastTimestamp.current) / 1000;
    const loadedBytes =
      config.bytesPerChunk * currentChunk.current - lastUploadedBytes.current;

    networkSpeed.current = (loadedBytes / timeDuration / 1024 / 1024).toFixed(
      2
    );

    lastUploadedBytes.current = config.bytesPerChunk * currentChunk.current;
    lastTimestamp.current = currentTime;
  }

  function readNextChunk() {
    const start = currentChunk.current * config.bytesPerChunk;
    const end = Math.min(
      start + config.bytesPerChunk,
      location.state.file.size
    );
    fileReader.readAsArrayBuffer(location.state.file.slice(start, end));
  }

  fileReader.onload = function () {
    if (!finishedUpload.current) {
      connection.current.send({
        type: "chunk",
        data: fileReader.result,
        chunk: currentChunk.current,
      });
      currentChunk.current++;
      setProgressState(
        ((currentChunk.current + 1) / totalChunks.current) * 100
      );
      if (
        currentChunk.current * config.bytesPerChunk <
        location.state.file.size
      ) {
        readNextChunk();
      } else {
        finishedUpload.current = true;
        setTimeout(() => peer.current.destroy(), 2000);
        navigate("/", { state: { message: "Upload complete!" } });
      }
    }
  };

  React.useEffect(() => {
    Axios.get(config.apiUrl + "/pin").then((response) => {
      setUploadPin(response.data.pin);
    });
  }, []);

  React.useEffect(() => {
    if (uploadPin !== null && uploadPin !== "") {
      peer.current = new Peer("warpfile-uploader-" + uploadPin, config.server);

      peer.current.on("open", function (id) {});
      peer.current.on("connection", function (conn) {
        if (conn.peer === "warpfile-downloader-" + uploadPin) {
          conn.on("open", function () {
            connection.current = conn;
            initialConnection.current = true;
            lastTimestamp.current = new Date().getTime();
            lastUploadedBytes.current = 0;
            totalChunks.current = Math.ceil(
              location.state.file.size / config.bytesPerChunk
            );
            connection.current.send({
              type: "start",
              meta: {
                name: location.state.file.name,
                size: location.state.file.size,
                type: location.state.file.type,
                totalChunks: totalChunks.current,
              },
            });
            finishedUpload.current = false;
            currentChunk.current = 0;
            setInterval(getConnectionStats, 1000);
            readNextChunk();
          });
          conn.on("close", function () {
            if (!finishedUpload.current) {
              finishedUpload.current = true;
              navigate("/", {
                state: { error: "Lost connection to the downloader." },
              });
            }
          });
        } else {
          connection.current.destroy();
        }
      });
      peer.current.on("disconnected", function () {
        if (!finishedUpload.current) {
          if (initialConnection.current) {
            navigate("/", {
              state: { error: "Lost connection to the downloader." },
            });
          } else {
            navigate("/", { state: { error: "Unknown Error" } });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadPin]);

  return (
    <Row>
      <Card
        title="Direct Transfer"
        col={
          location.state.file.size <= sizeStringToBytes(config.maxSizeForUpload)
            ? "2"
            : "1"
        }
      >
        <When condition={initialConnection.current}>
          <TransferStatus
            progress={progressState}
            speed={networkSpeed.current}
            speedUnit="MB"
          />
        </When>
        <When condition={!initialConnection.current}>
          <PinDisplay pin={uploadPin} />
        </When>
        <AbortButton onClick={abort} />
      </Card>

      <When
        condition={() =>
          location.state.file.size <= sizeStringToBytes(config.maxSizeForUpload)
        }
      >
        <Card title="Upload to Server" col="2">
          Instead of directly sending the file to the other pc, you can upload
          the file to the server. It will be saved for 24h and will be deleted
          afterwards.
          <br />
          <br />
          <b>This function is not available as it is still in development.</b>
          <br />
          <br />
          <Button value="Upload to Server" onClick={dummy} disabled={true} />
        </Card>
      </When>
    </Row>
  );
};

export default Upload;
