import React from "react";
import "./MessageCard.css";

function MessageCard(props) {
  const cardRef = React.useRef();

  const close = () => {
    cardRef.current.remove();
  };

  return (
    <div className="MessageCard-parent">
      <div ref={cardRef} className="MessageCard-element">
        <h1>Info!</h1>
        <button onClick={close}>X</button>
        <br />
        <div className="MessageCard-content">{props.message}</div>
      </div>
    </div>
  );
}
export default MessageCard;
