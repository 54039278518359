import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import When from "../components/When";
import Row from "../components/Row";
import Card from "../components/Card";
import Button from "../components/Button";
import MessageCard from "../components/MessageCard";
import ErrorCard from "../components/ErrorCard";
import FileInput from "../components/FileInput";
import PinInput from "../components/PinInput";

const Home = (props) => {
  const uploadFileRef = React.useRef();
  const [uploadState, setUploadState] = React.useState("false");
  const [downloadState, setDownloadState] = React.useState("false");
  const [downloadPin, setDownloadPin] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [stateMessage, setStateMessage] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (location.state != null && location.state.error != null) {
      setErrorMessage(location.state.error);
    }
    if (location.state != null && location.state.message != null) {
      setStateMessage(location.state.message);
    }
  }, [location.state]);

  function upload() {
    navigate("/upload", { state: { file: uploadFileRef.current.getFile() } });
  }

  function download() {
    if (downloadPin.length === 6) {
      navigate("/direct-download", { state: { downloadPin: downloadPin } });
    }
  }

  function FileInputOnChange(e) {
    const input = e.file;
    if (!input) {
      setUploadState("false");
      return;
    }
    setUploadState("true");
  }

  function PinInputOnChange(e) {
    setDownloadPin(e.id);
    if (e.id.length >= 6) {
      setDownloadState("true");
    } else {
      setDownloadState("false");
    }
  }

  return (
    <>
      <When condition={errorMessage}>
        <ErrorCard errorMessage={errorMessage}></ErrorCard>
      </When>
      <When condition={stateMessage}>
        <MessageCard message={stateMessage}></MessageCard>
      </When>
      <Row>
        <Card title="Upload" col="2">
          <FileInput ref={uploadFileRef} onChange={FileInputOnChange} />
          <Button
            value="Upload"
            onClick={upload}
            disabled={uploadState === "false"}
          />
        </Card>
        <Card title="Download" col="2">
          <PinInput onChange={PinInputOnChange} />
          <Button
            value="Download"
            onClick={download}
            disabled={downloadState === "false"}
          />
        </Card>
      </Row>
    </>
  );
};

export default Home;
