import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Peer from "peerjs";
import config from "../config";
import Row from "../components/Row";
import Card from "../components/Card";
import AbortButton from "../components/AbortButton";
import TransferStatus from "../components/TransferStatus";

const DirectDownload = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialConnection = React.useRef(false);
  const peer = React.useRef(null);

  const receivedData = React.useRef([]);
  const fileMeta = React.useRef({});

  const [progressState, setProgressState] = React.useState("0");
  const currentChunk = React.useRef(0);
  const finishedUpload = React.useRef(false);
  const lastTimestamp = React.useRef(0);
  const lastUploadedBytes = React.useRef(0);
  const networkSpeed = React.useRef(0.0);

  function abort() {
    peer.current.destroy();
    navigate("/");
  }

  function getConnectionStats() {
    const currentTime = new Date().getTime();
    const timeDuration = (currentTime - lastTimestamp.current) / 1000;
    const loadedBytes =
      config.bytesPerChunk * currentChunk.current - lastUploadedBytes.current;

    networkSpeed.current = (loadedBytes / timeDuration / 1024 / 1024).toFixed(
      2
    );

    lastUploadedBytes.current = config.bytesPerChunk * currentChunk.current;
    lastTimestamp.current = currentTime;
  }

  React.useEffect(() => {
    peer.current = new Peer(
      "warpfile-downloader-" + location.state.downloadPin,
      config.server
    );
    peer.current.on("open", function (id) {
      var conn = peer.current.connect(
        "warpfile-uploader-" + location.state.downloadPin
      );
      conn.on("open", function () {
        initialConnection.current = true;
        conn.on("data", function (data) {
          if (data.type === "start") {
            fileMeta.current = data.meta;
            receivedData.current = [];
            lastTimestamp.current = new Date().getTime();
            lastUploadedBytes.current = 0;
            finishedUpload.current = false;
            setInterval(getConnectionStats, 1000);
          } else if (data.type === "chunk") {
            receivedData.current.push(data.data);
            currentChunk.current = data.chunk;
            setProgressState(
              ((data.chunk + 1) / fileMeta.current.totalChunks) * 100
            );
            if (data.chunk + 1 === fileMeta.current.totalChunks) {
              finishedUpload.current = true;
              const link = document.createElement("a");
              link.href = URL.createObjectURL(
                new Blob(receivedData.current, { type: fileMeta.current.type })
              );
              link.download = fileMeta.current.name;
              link.click();
              link.remove();
              setTimeout(() => URL.revokeObjectURL(link.href), 15000);
              setTimeout(() => peer.current.destroy(), 2000);
              navigate("/", { state: { message: "Upload complete!" } });
            }
          }
        });
      });
    });
    peer.current.on("disconnected", function () {
      if (!finishedUpload.current) {
        if (initialConnection.current) {
          navigate("/", {
            state: { error: "Lost connection to the uploader." },
          });
        } else {
          navigate("/", {
            state: { error: "You have entered a invalid Pin." },
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Card title="Direct Transfer" col={"1"}>
        <TransferStatus
          progress={progressState}
          speed={networkSpeed.current}
          speedUnit="MB"
        />
        <AbortButton onClick={abort} />
      </Card>
    </Row>
  );
};

export default DirectDownload;
